<template>
  <b-col sm="12" md="6" lg="4" class="mb-4">
    <b-card no-body class="blurred-bg">
      <b-tabs card fill>
        <b-tab :title="$t('main.blocks.center.upcoming')" id="next-tours" active>
          <div class="d-flex flex-column text-white">
            <router-link :to="dataSport.kindOfSport + '/tour/' + tour.id" v-for="tour in tours.next" :key="tour.id" class="py-2 game-border-bottom tour-link">
              <div class="text-left">
                <div class="text-uppercase font-weight-normal tour-date-font-size text-white">
                  <span v-if="$i18n.locale === 'ru'">{{tour.date}}</span>
                  <span v-else>{{tour.date_eng}}</span>
                  <span class="separator"> | </span>
                  <span v-if="$i18n.locale === 'ru'">{{tour.weekday}}</span>
                  <span v-else>{{tour.weekday_eng}}</span>
                  <span class="separator"> | </span>
                  <span>{{tour.time_start}}</span>
                </div>
                <div class="small text-white-50" v-if="$i18n.locale === 'ru'">
                  {{tour.title}}
                </div>
                <div class="small text-white-50" v-else>
                  {{tour.title_eng}}
                </div>
              </div>
            </router-link>
          </div>
        </b-tab>
        <b-tab :title="$t('main.blocks.center.finished')" id="prev-tours">
          <div class="d-flex flex-column text-white">
            <router-link :to="dataSport.kindOfSport + '/tour/' + tour.id" v-for="tour in tours.prev" :key="tour.id" class="py-2 game-border-bottom tour-link">
              <div class="text-left">
                <div class="text-uppercase font-weight-normal tour-date-font-size text-white">
                  <span v-if="$i18n.locale === 'ru'">{{tour.date}}</span>
                  <span v-else>{{tour.date_eng}}</span>
                  <span class="separator"> | </span>
                  <span v-if="$i18n.locale === 'ru'">{{tour.weekday}}</span>
                  <span v-else>{{tour.weekday_eng}}</span>
                  <span class="separator"> | </span>
                  <span>{{tour.time_start}}</span>
                </div>
                <div class="small text-white-50" v-if="$i18n.locale === 'ru'">
                  {{tour.title}}
                </div>
                <div class="small text-white-50" v-else>
                  {{tour.title_eng}}
                </div>
              </div>
            </router-link>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-col>
</template>

<script>

  export default {
    name: "MainCenterBlock",
    props: ['dataSport'],
    data() {
      return {
        tours: []
      };

    },
    mounted() {
      this.$http.post(this.$url + "api/" + this.dataSport.link_api + "/tours/load/main").then(response => (this.tours = response.data));
      //console.log(this.tours);
    }
  }
</script>

<style scoped>
  .separator{
    font-size: 11px;
    vertical-align: text-bottom;
    padding: 0 2px;
  }

  .tour-date-font-size {
    font-size: .78rem;
  }

  .tour-link{
    text-decoration: none;
    transition: all 0.5s;
  }

  .tour-link:hover{
    padding-left: 8px;
    transition: all 0.5s;
  }
</style>