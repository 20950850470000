<template>
  <b-col sm="12" md="6" lg="4" class="mb-4">
    <b-card no-body class="blurred-bg">
      <b-tabs card fill lazy>
        <b-tab
          :title="$t('main.blocks.left.today')"
          class="p-0"
          v-on:click="activeTabF('today')"
          v-bind:active="activeTab === 'today'"
        >
          <b-card no-body>
            <b-tabs card fill end lazy>
              <b-tab
                :title="$i18n.locale === 'ru' ? tour_t.title : tour_t.title_eng"
                v-for="(tour_t, index) in games.today"
                v-bind:key="tour_t.id"
                v-on:click="activeTabTF('today_' + index)"
                v-bind:active="activeTabT === 'today_' + index"
              >
                <MlbTourStages
                  :stages="tour_t.stages"
                  :is_1x1="tour_t.is_1x1"
                  :tourid="tour_t.id"
                  :dataSport="dataSport"
                ></MlbTourStages>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-tab>
        <b-tab
          :title="$t('main.blocks.left.yesterday')"
          class="p-0"
          v-on:click="activeTabF('yesterday')"
          v-bind:active="activeTab === 'yesterday'"
        >
          <b-card no-body>
            <b-tabs card fill end lazy>
              <b-tab
                :title="$i18n.locale === 'ru' ? tour_y.title : tour_y.title_eng"
                v-for="(tour_y, index) in games.yesterday"
                v-bind:key="index"
                v-on:click="activeTabYF('yesterday_' + index)"
                v-bind:active="activeTabY === 'yesterday_' + index"
              >
                <MlbTourStages
                  :stages="tour_y.stages"
                  :is_1x1="tour_y.is_1x1"
                  :tourid="tour_y.id"
                  :dataSport="dataSport"
                ></MlbTourStages>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-col>
</template>

<script>
export default {
  name: "MainLeftBlock",
  props: ["dataSport"],
  data() {
    return {
      games: [],
      isActive: true,
      activeTab: window.localStorage.getItem(
        this.dataSport.kindOfSport + "_activeTab"
      ),
      activeTabT: window.localStorage.getItem(
        this.dataSport.kindOfSport + "_activeTabT"
      ),
      activeTabY: window.localStorage.getItem(
        this.dataSport.kindOfSport + "_activeTabY"
      ),
      tabIndex: 0,
      tabIndexToday: 0,
      tabIndexYesterday: 0
    };
  },
  components: {
    MlbTourStages: () =>
      import("@/components/blocks/counter_strike/MlbTourStages.vue")
  },
  mounted() {
    this.$http
      .post(this.$url + "api/" + this.dataSport.link_api + "/games/load/main")
      .then(response => (this.games = response.data));
  },
  methods: {
    activeTabF(tab) {
      window.localStorage.setItem(
        this.dataSport.kindOfSport + "_activeTab",
        tab
      );
    },
    activeTabTF(tabT) {
      window.localStorage.setItem(
        this.dataSport.kindOfSport + "_activeTabT",
        tabT
      );
    },
    activeTabYF(tabY) {
      window.localStorage.setItem(
        this.dataSport.kindOfSport + "_activeTabY",
        tabY
      );
    }
  }
};
</script>

<style scoped>
.separator {
  font-size: 17px;
  font-weight: 400;
}
.card-footer {
  padding: 0.75rem 0;
}
</style>
