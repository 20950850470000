<template>
  <b-col sm="12" md="12" lg="4" class="mb-4">
    <b-card no-body id="teams-block" class="blurred-bg">
      <b-tabs card>
        <b-tab :title="$t('main.blocks.right.teams')" active>
          <div
            v-for="team in teams"
            :key="team.ID"
            class="py-2 game-border-bottom tour-link"
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-1 align-self-center text-left">
                <img
                  :src="team.filetime ? ($url+'upload/images/teams/'+ team.ID_SL +'/100x100.png?v='+ team.filetime) : '/img/icons/stub.png'"
                  width="30"
                  height="30"
                  class="rounded-sm"
                />
              </div>
              <div class="w-100 pl-3">
                <div
                  class="text-uppercase font-weight-normal tour-date-font-size text-white"
                >
                  <span
                    class="small"
                    v-text="$i18n.locale === 'ru' ? team.Title : team.Title_eng"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <!--b-card-footer class="text-center px-2">
        <div class="openCloseButton text-uppercase font-weight-normal text-white d-inline-block px-3 py-2" @click="tabOpenClose">
          <span>{{ tabButtonText }}</span>
        </div>
      </b-card-footer-->
    </b-card>
  </b-col>
</template>

<script>
export default {
  name: "MainRightBlock",
  props: ["dataSport"],
  tabButtonText: "",
  data() {
    return {
      teams: [],
      isActive: true,
      tabShow: false,
      tab_height: 20 * 47
    };
  },
  mounted() {
    this.$http
      .post(this.$url + "api/" + this.dataSport.link_api + "/teams/load/main")
      .then(response => (this.teams = response.data));
    /*
    document
      .getElementById("teams-block")
      .getElementsByClassName("tab-content")[0].style.height =
      this.tab_height + "px";
     */
  },
  methods: {
    tabOpenClose: function(e) {
      let tabBody = document
        .getElementById("teams-block")
        .getElementsByClassName("tab-content")[0];
      this.tabShow = !this.tabShow;

      if (this.tabShow) {
        tabBody.style.height = "auto";
        tabBody.style.overflow = "auto";
      } else {
        tabBody.style.height = this.tab_height + "px";
        tabBody.style.overflow = "hidden";
      }
    }
  },
  computed: {
    tabButtonText: function() {
      if (this.tabShow) {
        return this.$t("main.blocks.right.tab_close");
      } else {
        return this.$t("main.blocks.right.tab_open");
      }
    }
  }
};
</script>

<style>
#teams-block .tab-content {
  overflow: hidden;
}
.openCloseButton {
  background-color: rgba(0, 0, 0, 0.48);
  border-radius: 0.25rem;
  font-size: 12px;
  cursor: pointer;
}
.openCloseButton:hover {
  background-color: rgba(0, 0, 0, 0.24);
}
</style>
